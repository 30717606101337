import {
  Menu,
  MenuItem,
  Text,
  useAuthenticator,
  View,
  Flex,
  useTheme,
  Card,
  Grid,
} from '@aws-amplify/ui-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './pages/Home';
import { Inscribir } from './pages/Inscribir';
import { Checkout } from './pages/Checkout';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51O4E8FHdToX2UKoVLJ9LfsbALbALHVXxwiH1bPLr4jhmziKS56YN8vuJvkKpSn2nWfh3oAkMgBQhSkNlRJ8s77bD0032FBPzh2'
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/nueva-inscripcion',
    element: <Inscribir />,
  },
  {
    path: '/checkout',
    element: <Checkout stripePromise={stripePromise} />,
  },
]);

export function Container() {
  const { tokens } = useTheme();
  const { signOut, user } = useAuthenticator();
  return (
    <View>
      <Grid templateColumns='1fr'>
        <Card
          variation='elevated'
          width='100%'
          height='unset'
          position='relative'
          columnStart='1'
          columnEnd='-1'
        >
          <Flex direction={'row'}>
            <Menu menuAlign='start' size='large'>
              <MenuItem fontSize={tokens.fontSizes.small} fontWeight={'normal'} onClick={signOut}>
                Salir
              </MenuItem>
            </Menu>
            <Text alignSelf={'center'} fontSize={tokens.fontSizes.medium}>
              Bienvenido <strong>{`${user.attributes?.given_name}`}</strong>
            </Text>
          </Flex>
        </Card>
        <Flex
          columnStart='1'
          columnEnd='-1'
          width='100%'
          height='100%'
          direction='column'
          position='relative'
          paddingTop={tokens.space.large}
          paddingBottom={tokens.space.large}
          alignItems={'center'}
        >
          <RouterProvider router={router} />
        </Flex>

        <View padding={tokens.space.large} backgroundColor={tokens.colors.background.secondary}>
          <Text color={tokens.colors.neutral[80]} fontSize={'0.8em'} textAlign={'center'}>
            &copy; Federación Felina de México, A.C. Todos los derechos reservados.
          </Text>
        </View>
      </Grid>
    </View>
  );
}
