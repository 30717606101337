import { Authenticator, Image, Text, View, useTheme } from '@aws-amplify/ui-react';

const formFields = {
  signUp: {
    given_name: {
      isRequired: true,
      order: 1,
    },
    family_name: {
      isRequired: true,
      order: 2,
    },
    phone_number: {
      isRequired: true,
      order: 3,
      dialCode: '+52',
    },
    email: {
      order: 4,
    },
    password: {
      order: 5,
    },
    confirm_password: {
      order: 6,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign='center' padding={tokens.space.large}>
        <Image alt='FIFe FFM' src={`/logo.svg`} padding={'5%'} height={'140px'} />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign='center' padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]} fontSize={'0.8em'}>
          &copy; Federación Felina de México, A.C. Todos los derechos reservados.
        </Text>
      </View>
    );
  },
};

export function Auth({ children }: any) {
  return (
    <>
      <Authenticator initialState='signUp' formFields={formFields} components={components}>
        {children}
      </Authenticator>
    </>
  );
}
