import {
  Badge,
  Button,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  useTheme,
} from '@aws-amplify/ui-react';
import { FaEdit, FaCat, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export function Home() {
  const { tokens } = useTheme();
  const navigate = useNavigate();

  const items = [
    {
      id: '01HDC5AE45HR90ZRYPXMS2NVAR',
      alias: 'FIFe: (MX) FFM LO 00000126',
      name: 'MX* Annyakunz Sköl',
      status: 'CONFIRMED',
    },
    {
      id: '01HDC5AE45HR90ZRYPXMS2NVAR',
      alias: '(MX) FFM LO 00000156',
      name: 'MX* Annyakunz Aslan',
      status: 'ON_REVIEW',
    },
    {
      id: '01HDC5AE45HR90ZRYPXMS2NVAR',
      alias: '(MX) FFM LO 00000106',
      name: 'DK* Mainestryg Gurli',
      status: 'DECLINED',
    },
    {
      id: '01HDC5AE45HR90ZRYPXMS2NVAR',
      alias: '(MX) FFM LO 00000186',
      name: 'Odin Kunturi Muhtesem',
      status: 'PAYMENT_PENDING',
    },
    {
      id: '01HDC5AE45HR90ZRYPXMS2NVAR',
      alias: '(MX) FFM LO 00000196',
      name: 'DK* Lyngkatten Lucifer Morgninstar',
    },
  ];
  return (
    <Flex direction={'column'} width={'100%'}>
      <Button
        variation='primary'
        width={'200px'}
        margin={tokens.space.small}
        fontSize={tokens.fontSizes.small}
        alignSelf={'center'}
        onClick={() => {
          console.log('click');
          navigate('/nueva-inscripcion');
        }}
      >
        <FaCat />
        <Text paddingLeft={tokens.space.small} color={tokens.colors.white}>
          Nueva inscripción
        </Text>
      </Button>
      <Table
        caption=''
        highlightOnHover={true}
        maxWidth={'640px'}
        alignSelf={'center'}
        backgroundColor={tokens.colors.background.primary}
      >
        <TableHead>
          <TableRow>
            <TableCell as='th' fontSize={tokens.fontSizes.xs}>
              Participante
            </TableCell>
            <TableCell as='th' fontSize={tokens.fontSizes.xs} textAlign={'center'}>
              Estado
            </TableCell>
            <TableCell as='th' fontSize={tokens.fontSizes.xs} textAlign={'center'}>
              Opciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={item.alias}>
              <TableCell>
                <Flex direction={'column'} gap={tokens.space.xxs}>
                  <Text variation='tertiary' fontSize={tokens.fontSizes.xs}>
                    {item.alias}
                  </Text>
                  <Text fontSize={tokens.fontSizes.small}>{item.name}</Text>
                </Flex>
              </TableCell>
              <TableCell textAlign={'center'}>
                <Badge
                  variation={
                    item.status === 'CONFIRMED'
                      ? 'success'
                      : item.status === 'ON_REVIEW'
                      ? 'warning'
                      : item.status === 'PAYMENT_PENDING'
                      ? 'info'
                      : item.status === 'DECLINED'
                      ? 'error'
                      : undefined
                  }
                  fontSize={tokens.fontSizes.xs}
                  alignSelf={'center'}
                >
                  {item.status === 'CONFIRMED'
                    ? 'Confirmado'
                    : item.status === 'ON_REVIEW'
                    ? 'En Revisión'
                    : item.status === 'DECLINED'
                    ? 'Rechazado'
                    : item.status === 'PAYMENT_PENDING'
                    ? 'Pendiente de Pago'
                    : 'Desconocido'}
                </Badge>
              </TableCell>
              <TableCell textAlign={'center'}>
                {item.status === 'CONFIRMED' || item.status === 'DECLINED' ? (
                  <Button
                    gap='0.1rem'
                    size='small'
                    variation='link'
                    alignSelf={'center'}
                    onClick={() => navigate(`/inscripcion/${item.id}`)}
                  >
                    <FaEye />
                  </Button>
                ) : (
                  <Button
                    gap='0.1rem'
                    size='small'
                    variation='link'
                    alignSelf={'center'}
                    placeholder='Editar'
                    onClick={() => navigate(`/inscripcion/${item.id}`)}
                  >
                    <FaEdit />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Flex>
  );
}
