import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Sign In': 'Ingresar',
    'Create Account': 'Registrarse',
    'Given Name': 'Nombres',
    'Enter your Given Name': 'Ingrese sus nombres',
    'Family Name': 'Apellidos',
    'Enter your Family Name': 'Ingrese sus apellidos',
    'Phone Number': 'Número telefónico',
    'Enter your Phone Number': 'Ingrese su número telefónico',
    Email: 'Correo electrónico',
    'Enter your Email': 'Ingrese su correo electrónico',
    'Enter your Password': 'Ingrese su contraseña',
    'Please confirm your Password': 'Ingrese nuevamente su contraseña',
    Birthdate: 'Fecha de nacimiento',
    'Username cannot be empty': 'Correo electrónico obligatorio',
    'Password cannot be empty': 'Contraseña obligatoria',
    'Reset Password': 'Recuperar contraseña',
    'Enter your email': 'Correo electrónico',
    'Code *': 'Código *',
  },
});
