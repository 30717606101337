import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import CheckoutForm from '../components/CheckoutForm';
import { API } from 'aws-amplify';

export type CheckoutProps = {
  stripePromise: Promise<Stripe | null>;
};

export function Checkout(props: CheckoutProps) {
  const [clientSecret, setClientSecret] = useState('');

  /*
  useEffect(async () => {
    //API.graphql
    fetch('/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);*/

  return (
    <>
      {clientSecret && (
        <Elements options={{ clientSecret }} stripe={props.stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}
