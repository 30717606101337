import { useState } from 'react';

import { AssociationOptions } from '../data/association';
import { TitleOptions } from '../data/title';
import EditInscription, {
  EditInscriptionInputValues,
  ValidationResponse,
} from '../components/EditInscription';
import { Breadcrumbs, useTheme } from '@aws-amplify/ui-react';
import { MdChevronRight } from 'react-icons/md';

function pageStateToFormState(newInscription: any): EditInscriptionInputValues {
  return {
    name: newInscription.name,
    hasPedigree: newInscription.hasPedigree,
    pedigreeAssociation: newInscription.pedigree.association,
    pedigreeCode: newInscription.pedigree.code,
    microchipNumber: newInscription.microchipNumber,
    birthdate: newInscription.birthdate,
    emsCode: newInscription.emsCode,
    sex: newInscription.sex,
    isNeutered: newInscription.isNeutered,
    lastTitle: newInscription.lastTitle,

    unknownSire: newInscription.unknownSire,
    sireName: newInscription.sire.name,
    sireEmsCode: newInscription.sire.emsCode,
    sirePedigreeAssociation: newInscription.sire.pedigree.association,
    sirePedigreeCode: newInscription.sire.pedigree.code,

    unknownDam: newInscription.unknownDam,
    damName: newInscription.dam.name,
    damEmsCode: newInscription.dam.emsCode,
    damPedigreeAssociation: newInscription.dam.pedigree.association,
    damPedigreeCode: newInscription.dam.pedigree.code,
  };
}

function formStateToPageState(fields: EditInscriptionInputValues) {
  return {
    name: fields.name!,
    hasPedigree: fields.hasPedigree!,
    pedigree: fields.hasPedigree!
      ? {
          association: fields.pedigreeAssociation ?? '',
          code: fields.pedigreeCode ?? '',
        }
      : { association: '', code: '' },
    microchipNumber: fields.microchipNumber!,
    birthdate: fields.birthdate!,
    emsCode: fields.emsCode!,
    sex: fields.sex!,
    isNeutered: fields.isNeutered!,
    lastTitle: !fields.hasPedigree ? '' : fields.lastTitle!,
    unknownSire: fields.unknownSire!,
    sire: !fields.unknownSire
      ? {
          name: fields.sireName ?? '',
          emsCode: fields.sireEmsCode ?? '',
          pedigree: {
            code: fields.sirePedigreeCode ?? '',
            association: fields.sirePedigreeAssociation ?? '',
          },
        }
      : {
          name: '',
          emsCode: '',
          pedigree: { association: '', code: '' },
        },
    unknownDam: fields.unknownDam!,
    dam: !fields.unknownDam
      ? {
          name: fields.damName ?? '',
          emsCode: fields.damEmsCode ?? '',
          pedigree: {
            code: fields.damPedigreeCode ?? '',
            association: fields.damPedigreeAssociation ?? '',
          },
        }
      : {
          name: '',
          emsCode: '',
          pedigree: { association: '', code: '' },
        },
  };
}

export function Inscribir() {
  const initialState = {
    name: '',
    emsCode: '',
    hasPedigree: true,
    pedigree: {
      code: '',
      association: '',
    },
    sex: 'MALE',
    isNeutered: false,
    lastTitle: '',
    unknownSire: false,
    sire: {
      name: '',
      emsCode: '',
      pedigree: {
        code: '',
        association: '',
      },
    },
    unknownDam: false,
    dam: {
      name: '',
      emsCode: '',
      pedigree: {
        code: '',
        association: '',
      },
    },
  };
  const [inscription, setInscription] = useState(initialState);
  const {tokens} = useTheme();

  return (
    <>
      <Breadcrumbs
        items={[
          {
            href: '/',
            label: 'Inicio',
          },
          {
            label: 'Nueva Inscripción',
          },
        ]}
        separator={<MdChevronRight />}
        alignSelf={'flex-start'}
        paddingLeft={tokens.space.medium}
        paddingRight={tokens.space.medium}
      />
      <EditInscription
        overrides={{
          isNeutered: { isChecked: inscription.isNeutered },
          hasPedigree: {
            isChecked: inscription.hasPedigree,
          },
          pedigreeAssociation: {
            options: AssociationOptions,
            isDisabled: !inscription.hasPedigree,
            value: inscription.pedigree.association,
            ...(!inscription.hasPedigree ? { errorMessage: '' } : {}),
          },
          pedigreeCode: {
            isDisabled: !inscription.hasPedigree,
            value: inscription.pedigree.code,
            ...(!inscription.hasPedigree ? { errorMessage: '' } : {}),
          },
          sex: { direction: 'row', value: inscription.sex },
          lastTitle: {
            isDisabled: !inscription.hasPedigree,
            options: TitleOptions(inscription.isNeutered),
            value: inscription.lastTitle,
          },
          unknownSire: {
            isChecked: inscription.unknownSire,
          },
          sireName: {
            isDisabled: inscription.unknownSire,
            isRequired: !inscription.unknownSire,
            value: inscription.sire.name,
            ...(inscription.unknownSire ? { errorMessage: '' } : {}),
          },
          sireEmsCode: {
            isDisabled: inscription.unknownSire,
            value: inscription.sire.emsCode,
            ...(inscription.unknownSire ? { errorMessage: '' } : {}),
          },
          sirePedigreeAssociation: {
            isDisabled: inscription.unknownSire,
            options: AssociationOptions,
            value: inscription.sire.pedigree.association,
            ...(inscription.unknownSire ? { errorMessage: '' } : {}),
          },
          sirePedigreeCode: {
            isDisabled: inscription.unknownSire,
            value: inscription.sire.pedigree.code,
            ...(inscription.unknownSire ? { errorMessage: '' } : {}),
          },
          unknownDam: {
            isChecked: inscription.unknownDam,
          },
          damName: {
            isDisabled: inscription.unknownDam,
            isRequired: !inscription.unknownDam,
            value: inscription.dam.name,
            ...(inscription.unknownDam ? { errorMessage: '' } : {}),
          },
          damEmsCode: {
            isDisabled: inscription.unknownDam,
            value: inscription.dam.emsCode,
            ...(inscription.unknownDam ? { errorMessage: '' } : {}),
          },
          damPedigreeAssociation: {
            isDisabled: inscription.unknownDam,
            options: AssociationOptions,
            value: inscription.dam.pedigree.association,
            ...(inscription.unknownDam ? { errorMessage: '' } : {}),
          },
          damPedigreeCode: {
            isDisabled: inscription.unknownDam,
            value: inscription.dam.pedigree.code,
            ...(inscription.unknownDam ? { errorMessage: '' } : {}),
          },
        }}
        onValidate={{
          name: async (name): Promise<ValidationResponse> => {
            if (!name)
              return {
                hasError: true,
                errorMessage: 'El nombre del participante es obligatorio',
              };
            return { hasError: false, errorMessage: '' };
          },
          pedigreeAssociation: async (pedigreeAssociation): Promise<ValidationResponse> => {
            if (inscription.hasPedigree && !pedigreeAssociation)
              return {
                hasError: true,
                errorMessage: 'La organización emisora del pedigree es obligatoria',
              };
            return { hasError: false, errorMessage: '' };
          },
          pedigreeCode: async (pedigreeCode): Promise<ValidationResponse> => {
            if (inscription.hasPedigree && !pedigreeCode)
              return {
                hasError: true,
                errorMessage: 'El código de pedigree es obligatorio',
              };
            return { hasError: false, errorMessage: '' };
          },
          microchipNumber: async (microchipNumber): Promise<ValidationResponse> => {
            if (!microchipNumber)
              return {
                hasError: true,
                errorMessage: 'El número de microchip es obligatorio',
              };
            return { hasError: false, errorMessage: '' };
          },
          sireName: async (sireName): Promise<ValidationResponse> => {
            if (!inscription.unknownSire && !sireName)
              return {
                hasError: true,
                errorMessage: 'El nombre del padre es obligatorio',
              };
            return { hasError: false, errorMessage: '' };
          },
          sirePedigreeAssociation: async (sirePedigreeAssociation): Promise<ValidationResponse> => {
            if (!inscription.unknownSire && !inscription.sire.emsCode && !sirePedigreeAssociation)
              return {
                hasError: true,
                errorMessage:
                  'La organización emisora del pedigree es obligatoria si no hay código EMS',
              };
            return { hasError: false, errorMessage: '' };
          },
          sirePedigreeCode: async (sirePedigreeCode): Promise<ValidationResponse> => {
            if (!inscription.unknownSire && !inscription.sire.emsCode && !sirePedigreeCode)
              return {
                hasError: true,
                errorMessage: 'El código de pedigree es obligatorio si no hay código EMS',
              };
            return { hasError: false, errorMessage: '' };
          },
          damName: async (damName): Promise<ValidationResponse> => {
            if (!inscription.unknownDam && !damName)
              return {
                hasError: true,
                errorMessage: 'El nombre de la madre es obligatorio',
              };
            return { hasError: false, errorMessage: '' };
          },
          damPedigreeAssociation: async (damPedigreeAssociation): Promise<ValidationResponse> => {
            if (!inscription.unknownDam && !inscription.dam.emsCode && !damPedigreeAssociation)
              return {
                hasError: true,
                errorMessage:
                  'La organización emisora del pedigree es obligatoria si no hay código EMS',
              };
            return { hasError: false, errorMessage: '' };
          },
          damPedigreeCode: async (damPedigreeCode): Promise<ValidationResponse> => {
            if (!inscription.unknownDam && !inscription.dam.emsCode && !damPedigreeCode)
              return {
                hasError: true,
                errorMessage: 'El código de pedigree es obligatorio si no hay código EMS',
              };
            return { hasError: false, errorMessage: '' };
          },
          class: async (inscritionClass): Promise<ValidationResponse> => {
            if (!inscritionClass)
              return {
                hasError: true,
                errorMessage: 'El clase de compentencia es obligatoria',
              };
            return { hasError: false, errorMessage: '' };
          },
        }}
        onSubmit={function (fields: EditInscriptionInputValues): void {
          console.log(fields);
        }}
        onChange={function (fields: EditInscriptionInputValues): EditInscriptionInputValues {
          const newInscription = formStateToPageState(fields);
          setInscription(newInscription);
          return pageStateToFormState(newInscription);
        }}
      />
    </>
  );
}
