export const Titles = [
  { code: 'PR', name: 'Premier' },
  { code: 'CH', name: 'Champion' },
  { code: 'IP', name: 'International Premier' },
  { code: 'IC', name: 'International Champion' },
  { code: 'GIP', name: 'Grand International Premier' },
  { code: 'GIC', name: 'Grand International Champion' },
  { code: 'SP', name: 'Supreme Premier' },
  { code: 'SC', name: 'Supreme Champion' },
];

export const TitleOptions = (isNeutered: boolean) =>
  Titles.filter(
    ({ name }) =>
      (!isNeutered && name.includes('Champion')) || (isNeutered && name.includes('Premier'))
  ).map(({ code, name }) => `${code} - ${name}`);
export const TitleOptionsMap = new Map(
  Titles.map(({ code, name }) => [`${code} - ${name}`, code.toUpperCase()])
);
