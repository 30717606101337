export const Associations = [
  { code: 'FIFe', name: 'Fédération Internationale Féline' },
  { code: 'CFA', name: "Cat Fanciers' Association" },
  { code: 'TICA', name: 'The International Cat Association' },
  { code: 'WCF', name: 'World Cat Federation' },
  { code: 'ACF', name: 'Australian Cat Federation' },
  { code: 'NZCF', name: 'New Zealand Cat Fancy' },
  { code: 'CCCA', name: 'Co-Ordinating Cat Council of Australia' },
  { code: 'GCCF', name: 'Governing Council of the Cat Fancy' },
  { code: 'SACC', name: 'Southern African Cat Council' },
];

export const AssociationOptions = Associations.map(({ code, name }) => `${code} - ${name}`);
export const AssociationOptionsMap = new Map(
  Associations.map(({ code, name }) => [`${code} - ${name}`, code.toUpperCase()])
);
