import './App.css';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { studioTheme } from './ui-components';
import { Auth } from './Auth';
import { Container } from './Container';

function App() {
  return (
    <ThemeProvider theme={studioTheme}>
      <Auth>
        <Container></Container>
      </Auth>
    </ThemeProvider>
  );
}

export default App;
