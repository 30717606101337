import * as React from 'react';
import {
  Autocomplete,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Loader,
  Radio,
  RadioGroupField,
  SelectField,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from '@aws-amplify/ui-react';
import { getOverrideProps } from '@aws-amplify/ui-react/internal';
import { FaRegSadTear } from 'react-icons/fa';

export const fetchByPath = (input, path = '', accumlator = []) => {
  const currentPath = path.split('.');
  const head = currentPath.shift();
  if (input && head && input[head] !== undefined) {
    if (!currentPath.length) {
      accumlator.push(input[head]);
    } else {
      fetchByPath(input[head], currentPath.join('.'), accumlator);
    }
  }
  return accumlator[0];
};

export default function EditInscription(props) {
  const { tokens } = useTheme();
  const { onSubmit, onCancel, onValidate, onChange, overrides, ...rest } = props;
  const initialValues = {
    name: '',
    hasPedigree: true,
    pedigreeAssociation: '',
    pedigreeCode: '',
    microchipNumber: '',
    birthdate: '',
    emsCode: '',
    sex: 'MALE',
    isNeutered: false,
    lastTitle: '',
    unknownSire: false,
    sireName: '',
    sireEmsCode: '',
    sirePedigreeAssociation: '',
    sirePedigreeCode: '',
    unknownDam: false,
    damName: '',
    damEmsCode: '',
    damPedigreeAssociation: '',
    damPedigreeCode: '',
    class: '',
  };
  const [name, setName] = React.useState(initialValues.name);
  const [hasPedigree, setHasPedigree] = React.useState(initialValues.hasPedigree);
  const [pedigreeAssociation, setPedigreeAssociation] = React.useState(
    initialValues.pedigreeAssociation
  );
  const [pedigreeCode, setPedigreeCode] = React.useState(initialValues.pedigreeCode);
  const [microchipNumber, setMicrochipNumber] = React.useState(initialValues.microchipNumber);
  const [birthdate, setBirthdate] = React.useState(initialValues.birthdate);
  const [emsCode, setEmsCode] = React.useState(initialValues.emsCode);
  const [sex, setSex] = React.useState(initialValues.sex);
  const [isNeutered, setIsNeutered] = React.useState(initialValues.isNeutered);
  const [lastTitle, setLastTitle] = React.useState(initialValues.lastTitle);
  const [unknownSire, setUnknownSire] = React.useState(initialValues.unknownSire);
  const [sireName, setSireName] = React.useState(initialValues.sireName);
  const [sireEmsCode, setSireEmsCode] = React.useState(initialValues.sireEmsCode);
  const [sirePedigreeAssociation, setSirePedigreeAssociation] = React.useState(
    initialValues.sirePedigreeAssociation
  );
  const [sirePedigreeCode, setSirePedigreeCode] = React.useState(initialValues.sirePedigreeCode);
  const [unknownDam, setUnknownDam] = React.useState(initialValues.unknownDam);
  const [damName, setDamName] = React.useState(initialValues.damName);
  const [damEmsCode, setDamEmsCode] = React.useState(initialValues.damEmsCode);
  const [damPedigreeAssociation, setDamPedigreeAssociation] = React.useState(
    initialValues.damPedigreeAssociation
  );
  const [damPedigreeCode, setDamPedigreeCode] = React.useState(initialValues.damPedigreeCode);
  const [class1, setClass1] = React.useState(initialValues.class);
  const [errors, setErrors] = React.useState({});

  const validations = {
    name: [{ type: 'Required' }],
    hasPedigree: [],
    pedigreeAssociation: [],
    pedigreeCode: [],
    microchipNumber: [{ type: 'Required' }],
    birthdate: [{ type: 'Required' }],
    emsCode: [],
    sex: [{ type: 'Required' }],
    isNeutered: [],
    lastTitle: [],
    unknownSire: [],
    sireName: [{ type: 'Required' }],
    sireEmsCode: [],
    sirePedigreeAssociation: [],
    sirePedigreeCode: [],
    unknownDam: [],
    damName: [{ type: 'Required' }],
    damEmsCode: [],
    damPedigreeAssociation: [],
    damPedigreeCode: [],
    class: [],
  };

  const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
    const value = currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
    const customValidator = fetchByPath(onValidate, fieldName);
    let validationResponse = { hasError: false };
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  React.useEffect(() => {}, []);
  return (
    <Grid
      as='form'
      rowGap='15px'
      columnGap='15px'
      padding='20px'
      width={'640px'}
      onSubmit={async (event) => {
        event.preventDefault();
        const modelFields = {
          name,
          hasPedigree,
          pedigreeAssociation,
          pedigreeCode,
          microchipNumber,
          birthdate,
          emsCode,
          sex,
          isNeutered,
          lastTitle,
          unknownSire,
          sireName,
          sireEmsCode,
          sirePedigreeAssociation,
          sirePedigreeCode,
          unknownDam,
          damName,
          damEmsCode,
          damPedigreeAssociation,
          damPedigreeCode,
          class: class1,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) => runValidationTasks(fieldName, item))
              );
              return promises;
            }
            promises.push(runValidationTasks(fieldName, modelFields[fieldName]));
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        await onSubmit(modelFields);
      }}
      {...getOverrideProps(overrides, 'NewInscription')}
      {...rest}
    >
      <Heading
        level={5}
        children='Nueva Inscripción'
        {...getOverrideProps(overrides, 'formTitle')}
      ></Heading>
      <Text
        children='Nuevo participante para la Expo Gato Internacional'
        {...getOverrideProps(overrides, 'formDescription')}
      ></Text>
      <Heading children='Participante'></Heading>
      <Autocomplete
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Nombre</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        menuSlots={{
          Empty: (
            <Flex
              alignItems='center'
              fontSize={tokens.fontSizes.small}
              gap='0.25rem'
              alignContent={'center'}
            >
              <FaRegSadTear size={'32px'} />
              <Text paddingLeft={tokens.space.small} >No posee gatos registrados. Ingrese el nombre completo para continuar.</Text>
            </Flex>
          ),
          LoadingIndicator: (
            <Flex
              alignItems='center'
              fontSize={tokens.fontSizes.small}
              gap='0.25rem'
              alignContent={'center'}
            >
              <Loader paddingRight={tokens.space.medium} alignSelf={'center'} /> Buscando gatos
              registrados...
            </Flex>
          ),
        }}
        descriptiveText='Seleccione su gato o ingrese uno nuevo'
        isRequired={true}
        placeholder='Ingrese el nombre del gato'
        options={[]}
        onSelect={({ id, label }) => {
          setName(id);
          runValidationTasks('name', id);
        }}
        onClear={() => {
          setName('');
        }}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks('name', value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks('name', name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        labelHidden={false}
        {...getOverrideProps(overrides, 'name')}
      ></Autocomplete>
      <SwitchField
        label='¿Posee pedigree?'
        defaultChecked={false}
        isChecked={hasPedigree}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree: value,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.hasPedigree ?? value;
          }
          if (errors.hasPedigree?.hasError) {
            runValidationTasks('hasPedigree', value);
          }
          setHasPedigree(value);
        }}
        onBlur={() => runValidationTasks('hasPedigree', hasPedigree)}
        errorMessage={errors.hasPedigree?.errorMessage}
        hasError={errors.hasPedigree?.hasError}
        {...getOverrideProps(overrides, 'hasPedigree')}
      ></SwitchField>
      <SelectField
        label='Organización'
        descriptiveText='Seleccione la organización emisora del pedigree del participante'
        placeholder='Ninguna'
        value={pedigreeAssociation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation: value,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.pedigreeAssociation ?? value;
          }
          if (errors.pedigreeAssociation?.hasError) {
            runValidationTasks('pedigreeAssociation', value);
          }
          setPedigreeAssociation(value);
        }}
        onBlur={() => runValidationTasks('pedigreeAssociation', pedigreeAssociation)}
        errorMessage={errors.pedigreeAssociation?.errorMessage}
        hasError={errors.pedigreeAssociation?.hasError}
        {...getOverrideProps(overrides, 'pedigreeAssociation')}
      ></SelectField>
      <TextField
        label='Código o número de pedigree'
        placeholder='Ingrese el código o número de pedigree del participante'
        value={pedigreeCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode: value,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.pedigreeCode ?? value;
          }
          if (errors.pedigreeCode?.hasError) {
            runValidationTasks('pedigreeCode', value);
          }
          setPedigreeCode(value);
        }}
        onBlur={() => runValidationTasks('pedigreeCode', pedigreeCode)}
        errorMessage={errors.pedigreeCode?.errorMessage}
        hasError={errors.pedigreeCode?.hasError}
        {...getOverrideProps(overrides, 'pedigreeCode')}
      ></TextField>
      <TextField
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Número de Microchip</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        isRequired={true}
        placeholder='Ingrese el número de microchip del participante'
        value={microchipNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber: value,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.microchipNumber ?? value;
          }
          if (errors.microchipNumber?.hasError) {
            runValidationTasks('microchipNumber', value);
          }
          setMicrochipNumber(value);
        }}
        onBlur={() => runValidationTasks('microchipNumber', microchipNumber)}
        errorMessage={errors.microchipNumber?.errorMessage}
        hasError={errors.microchipNumber?.hasError}
        {...getOverrideProps(overrides, 'microchipNumber')}
      ></TextField>
      <TextField
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Fecha de nacimiento</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        isRequired={true}
        type='date'
        value={birthdate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate: value,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.birthdate ?? value;
          }
          if (errors.birthdate?.hasError) {
            runValidationTasks('birthdate', value);
          }
          setBirthdate(value);
        }}
        onBlur={() => runValidationTasks('birthdate', birthdate)}
        errorMessage={errors.birthdate?.errorMessage}
        hasError={errors.birthdate?.hasError}
        {...getOverrideProps(overrides, 'birthdate')}
      ></TextField>
      <TextField
        label='Código EMS'
        descriptiveText='*Para la clase 13c - Determination (Determinación) no es obligatorio'
        placeholder='Ingrese el código EMS del participante'
        value={emsCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode: value,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.emsCode ?? value;
          }
          if (errors.emsCode?.hasError) {
            runValidationTasks('emsCode', value);
          }
          setEmsCode(value);
        }}
        onBlur={() => runValidationTasks('emsCode', emsCode)}
        errorMessage={errors.emsCode?.errorMessage}
        hasError={errors.emsCode?.hasError}
        {...getOverrideProps(overrides, 'emsCode')}
      ></TextField>
      <RadioGroupField
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Sexo</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        name='fieldName'
        isRequired={true}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex: value,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.sex ?? value;
          }
          if (errors.sex?.hasError) {
            runValidationTasks('sex', value);
          }
          setSex(value);
        }}
        onBlur={() => runValidationTasks('sex', sex)}
        errorMessage={errors.sex?.errorMessage}
        hasError={errors.sex?.hasError}
        {...getOverrideProps(overrides, 'sex')}
      >
        <Radio children='Macho' value='Macho' {...getOverrideProps(overrides, 'sexRadio0')}></Radio>
        <Radio
          children='Hembra'
          value='Hembra'
          {...getOverrideProps(overrides, 'sexRadio1')}
        ></Radio>
      </RadioGroupField>
      <SwitchField
        label='¿Está castrado/a?'
        defaultChecked={false}
        isChecked={isNeutered}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered: value,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.isNeutered ?? value;
          }
          if (errors.isNeutered?.hasError) {
            runValidationTasks('isNeutered', value);
          }
          setIsNeutered(value);
        }}
        onBlur={() => runValidationTasks('isNeutered', isNeutered)}
        errorMessage={errors.isNeutered?.errorMessage}
        hasError={errors.isNeutered?.hasError}
        {...getOverrideProps(overrides, 'isNeutered')}
      ></SwitchField>
      <SelectField
        label='Último Título FIFe'
        descriptiveText='Selecione el último título FIFe obtenido'
        placeholder='Ninguno'
        value={lastTitle}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle: value,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.lastTitle ?? value;
          }
          if (errors.lastTitle?.hasError) {
            runValidationTasks('lastTitle', value);
          }
          setLastTitle(value);
        }}
        onBlur={() => runValidationTasks('lastTitle', lastTitle)}
        errorMessage={errors.lastTitle?.errorMessage}
        hasError={errors.lastTitle?.hasError}
        {...getOverrideProps(overrides, 'lastTitle')}
      ></SelectField>
      <Divider orientation='horizontal'></Divider>
      <Heading level={6} children='Padre'></Heading>
      <SwitchField
        label='¿Es desconocido?'
        defaultChecked={false}
        isChecked={unknownSire}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire: value,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.unknownSire ?? value;
          }
          if (errors.unknownSire?.hasError) {
            runValidationTasks('unknownSire', value);
          }
          setUnknownSire(value);
        }}
        onBlur={() => runValidationTasks('unknownSire', unknownSire)}
        errorMessage={errors.unknownSire?.errorMessage}
        hasError={errors.unknownSire?.hasError}
        {...getOverrideProps(overrides, 'unknownSire')}
      ></SwitchField>
      <TextField
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Nombre</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        isRequired={true}
        placeholder='Ingrese el nombre del padre'
        value={sireName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName: value,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.sireName ?? value;
          }
          if (errors.sireName?.hasError) {
            runValidationTasks('sireName', value);
          }
          setSireName(value);
        }}
        onBlur={() => runValidationTasks('sireName', sireName)}
        errorMessage={errors.sireName?.errorMessage}
        hasError={errors.sireName?.hasError}
        {...getOverrideProps(overrides, 'sireName')}
      ></TextField>
      <TextField
        label='Código EMS'
        descriptiveText='*Si no cuenta con el código, el ingreso del pedigree es obligatorio'
        placeholder='Ingrese el código EMS del padre'
        value={sireEmsCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode: value,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.sireEmsCode ?? value;
          }
          if (errors.sireEmsCode?.hasError) {
            runValidationTasks('sireEmsCode', value);
          }
          setSireEmsCode(value);
        }}
        onBlur={() => runValidationTasks('sireEmsCode', sireEmsCode)}
        errorMessage={errors.sireEmsCode?.errorMessage}
        hasError={errors.sireEmsCode?.hasError}
        {...getOverrideProps(overrides, 'sireEmsCode')}
      ></TextField>
      <SelectField
        label='Organización'
        descriptiveText='Seleccione la organización emisora del pedigree del padre'
        placeholder='Ninguna'
        value={sirePedigreeAssociation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation: value,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.sirePedigreeAssociation ?? value;
          }
          if (errors.sirePedigreeAssociation?.hasError) {
            runValidationTasks('sirePedigreeAssociation', value);
          }
          setSirePedigreeAssociation(value);
        }}
        onBlur={() => runValidationTasks('sirePedigreeAssociation', sirePedigreeAssociation)}
        errorMessage={errors.sirePedigreeAssociation?.errorMessage}
        hasError={errors.sirePedigreeAssociation?.hasError}
        {...getOverrideProps(overrides, 'sirePedigreeAssociation')}
      ></SelectField>
      <TextField
        label='Código o número de pedigree'
        placeholder='Ingrese el código de pedigree del padre'
        value={sirePedigreeCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode: value,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.sirePedigreeCode ?? value;
          }
          if (errors.sirePedigreeCode?.hasError) {
            runValidationTasks('sirePedigreeCode', value);
          }
          setSirePedigreeCode(value);
        }}
        onBlur={() => runValidationTasks('sirePedigreeCode', sirePedigreeCode)}
        errorMessage={errors.sirePedigreeCode?.errorMessage}
        hasError={errors.sirePedigreeCode?.hasError}
        {...getOverrideProps(overrides, 'sirePedigreeCode')}
      ></TextField>
      <Divider orientation='horizontal'></Divider>
      <Heading children='Madre'></Heading>
      <SwitchField
        label='¿Es desconocida?'
        defaultChecked={false}
        isChecked={unknownDam}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam: value,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.unknownDam ?? value;
          }
          if (errors.unknownDam?.hasError) {
            runValidationTasks('unknownDam', value);
          }
          setUnknownDam(value);
        }}
        onBlur={() => runValidationTasks('unknownDam', unknownDam)}
        errorMessage={errors.unknownDam?.errorMessage}
        hasError={errors.unknownDam?.hasError}
        {...getOverrideProps(overrides, 'unknownDam')}
      ></SwitchField>
      <TextField
        label={
          <span style={{ display: 'inline-flex' }}>
            <span>Nombre</span>
            <span style={{ color: 'red' }}>*</span>
          </span>
        }
        isRequired={true}
        placeholder='Ingrese el nombre de la madre'
        value={damName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName: value,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.damName ?? value;
          }
          if (errors.damName?.hasError) {
            runValidationTasks('damName', value);
          }
          setDamName(value);
        }}
        onBlur={() => runValidationTasks('damName', damName)}
        errorMessage={errors.damName?.errorMessage}
        hasError={errors.damName?.hasError}
        {...getOverrideProps(overrides, 'damName')}
      ></TextField>
      <TextField
        label='Código EMS'
        descriptiveText='*Si no cuenta con el código, el ingreso del pedigree es obligatorio'
        placeholder='Ingrese el código EMS de la madre'
        value={damEmsCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode: value,
              damPedigreeAssociation,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.damEmsCode ?? value;
          }
          if (errors.damEmsCode?.hasError) {
            runValidationTasks('damEmsCode', value);
          }
          setDamEmsCode(value);
        }}
        onBlur={() => runValidationTasks('damEmsCode', damEmsCode)}
        errorMessage={errors.damEmsCode?.errorMessage}
        hasError={errors.damEmsCode?.hasError}
        {...getOverrideProps(overrides, 'damEmsCode')}
      ></TextField>
      <SelectField
        label='Organización'
        descriptiveText='Seleccione la organización emisora del pedigree de la madre'
        placeholder='Ninguna'
        value={damPedigreeAssociation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation: value,
              damPedigreeCode,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.damPedigreeAssociation ?? value;
          }
          if (errors.damPedigreeAssociation?.hasError) {
            runValidationTasks('damPedigreeAssociation', value);
          }
          setDamPedigreeAssociation(value);
        }}
        onBlur={() => runValidationTasks('damPedigreeAssociation', damPedigreeAssociation)}
        errorMessage={errors.damPedigreeAssociation?.errorMessage}
        hasError={errors.damPedigreeAssociation?.hasError}
        {...getOverrideProps(overrides, 'damPedigreeAssociation')}
      ></SelectField>
      <TextField
        label='Código o número de pedigree'
        placeholder='Ingrese el código de pedigree de la madre'
        value={damPedigreeCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode: value,
              class: class1,
            };
            const result = onChange(modelFields);
            value = result?.damPedigreeCode ?? value;
          }
          if (errors.damPedigreeCode?.hasError) {
            runValidationTasks('damPedigreeCode', value);
          }
          setDamPedigreeCode(value);
        }}
        onBlur={() => runValidationTasks('damPedigreeCode', damPedigreeCode)}
        errorMessage={errors.damPedigreeCode?.errorMessage}
        hasError={errors.damPedigreeCode?.hasError}
        {...getOverrideProps(overrides, 'damPedigreeCode')}
      ></TextField>
      <Divider orientation='horizontal'></Divider>
      <Heading children='Inscripción'></Heading>
      <SelectField
        label='Clase'
        descriptiveText='Por favor seleccione la clase de competencia'
        placeholder='Ninguna'
        value={class1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              hasPedigree,
              pedigreeAssociation,
              pedigreeCode,
              microchipNumber,
              birthdate,
              emsCode,
              sex,
              isNeutered,
              lastTitle,
              unknownSire,
              sireName,
              sireEmsCode,
              sirePedigreeAssociation,
              sirePedigreeCode,
              unknownDam,
              damName,
              damEmsCode,
              damPedigreeAssociation,
              damPedigreeCode,
              class: value,
            };
            const result = onChange(modelFields);
            value = result?.class ?? value;
          }
          if (errors.class?.hasError) {
            runValidationTasks('class', value);
          }
          setClass1(value);
        }}
        onBlur={() => runValidationTasks('class', class1)}
        errorMessage={errors.class?.errorMessage}
        hasError={errors.class?.hasError}
        {...getOverrideProps(overrides, 'class')}
      ></SelectField>
      <Flex justifyContent='right' {...getOverrideProps(overrides, 'CTAFlex')}>
        <Flex gap='15px' {...getOverrideProps(overrides, 'RightAlignCTASubFlex')}>
          <Button
            children='Cancelar'
            type='button'
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(overrides, 'cancelButton')}
          ></Button>
          <Button
            children='Registrar'
            type='submit'
            variation='primary'
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, 'submitButton')}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
